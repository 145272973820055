/*window.$ = window.jQuery = require('jquery');
require('bootstrap-sass');
require('bootstrap-validator');*/
/*require('datatables.net');
require('datatables.net-bs');
require('datatables.net-responsive');
require('datatables.net-responsive-bs');
*/
$( document ).ready(function() {
    console.log($.fn.tooltip.Constructor.VERSION);
    //Datepicker
    $('.datepicker').datepicker();
    //Tooltips
    $('[data-toggle="tooltip"]').tooltip();
    //Datatables
    $('.datatable').DataTable({
        responsive: true,
        iDisplayLength:100
    });
    //Init Generate Password Button
    if ( $('.btn-generate-password').length ) {
        GeneratePasswordButton.add('.btn-generate-password');
    }
    //Input mask
    $(".input-mask").inputmask();
});